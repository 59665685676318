import { JsonLogicRule } from './types'

/**
 * Extracts the field, operator, and value from a JsonLogicRule
 *
 * JsonLogicRule structure:
 * {
 *   "operator": [{ "var": "fieldName" }, "value"]
 * }
 *
 * Where:
 * - operator is one of: ==, !=, in, none
 * - fieldName is the name of the field to check
 * - value is the value to compare against (string, number, or array of strings)
 */
export const extractRuleComponents = (
  ruleLogic: JsonLogicRule | string
): {
  field: string
  operator: string
  value: string | string[]
} => {
  // Default values
  let field = ''
  let operator = '=='
  let value: string | string[] = ''

  try {
    // Handle case where ruleLogic might be a string
    let parsedLogic: JsonLogicRule
    if (typeof ruleLogic === 'string') {
      try {
        parsedLogic = JSON.parse(ruleLogic)
      } catch (error) {
        console.error('Error parsing ruleLogic string in extractRuleComponents:', error)
        return { field, operator, value }
      }
    } else {
      parsedLogic = ruleLogic
    }

    // Get the operator (first key in the object)
    const operators = Object.keys(parsedLogic)
    if (operators.length > 0) {
      operator = operators[0]

      // Get the operands array
      const operands = parsedLogic[operator]

      if (Array.isArray(operands) && operands.length >= 2) {
        // First operand should be an object with a 'var' key
        const firstOperand = operands[0]
        if (firstOperand && typeof firstOperand === 'object' && 'var' in firstOperand) {
          field = firstOperand.var as string
        }

        // Second operand is the value
        value = operands[1]
      }
    }
  } catch (error) {
    console.error('Error extracting rule components:', error)
  }

  return { field, operator, value }
}

/**
 * Creates a JsonLogicRule from field, operator, and value components
 */
export const createRuleLogic = (field: string, operator: string, value: string | string[]): JsonLogicRule => {
  return {
    [operator]: [{ var: field }, value]
  }
}

/**
 * Formats a value for display in the UI based on the operator
 * For 'in' and 'none' operators, converts array to comma-separated string
 */
export const formatValueForDisplay = (value: string | string[], operator: string): string => {
  if ((operator === 'in' || operator === 'none') && Array.isArray(value)) {
    return value.join(', ')
  }
  return String(value)
}

/**
 * Parses a display value for storage in the rule logic
 * For 'in' and 'none' operators, converts comma-separated string to array
 */
export const parseValueForStorage = (value: string, operator: string): string | string[] => {
  if (operator === 'in' || operator === 'none') {
    // Split by comma and trim whitespace
    return value
      .split(',')
      .map((item) => item.trim())
      .filter((item) => item !== '')
  }
  return value
}
