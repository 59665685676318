import React, { FC, useEffect, useState } from 'react'
import { Select, Input } from 'antd'
import { RuleConditionFields, JsonLogicRule } from './types'
import './styles.modules.scss'
import { supportedOperators } from './utils'
import { extractRuleComponents, createRuleLogic, formatValueForDisplay, parseValueForStorage } from './ruleLogicUtils'

interface RuleLogicEditorProps {
  ruleLogic: JsonLogicRule
  supportedRuleConditionFields: RuleConditionFields[]
  editMode: boolean
  onRuleChange: (updatedRuleLogic: JsonLogicRule) => void
}

interface RuleComponents {
  field: string
  operator: string
  value: string | string[]
  displayValue: string
}

export const RuleLogicEditor: FC<RuleLogicEditorProps> = ({
  ruleLogic,
  supportedRuleConditionFields,
  editMode,
  onRuleChange
}) => {
  // Initialize state with extracted components
  const [components, setComponents] = useState<RuleComponents>(() => {
    const { field, operator, value } = extractRuleComponents(ruleLogic)
    return {
      field,
      operator,
      value,
      displayValue: formatValueForDisplay(value, operator)
    }
  })

  // Update state when ruleLogic changes
  useEffect(() => {
    const { field, operator, value } = extractRuleComponents(ruleLogic)
    setComponents({
      field,
      operator,
      value,
      displayValue: formatValueForDisplay(value, operator)
    })
  }, [ruleLogic])

  // Handlers for field, operator, and value changes
  const handleFieldChange = (newField: string) => {
    const updatedComponents = {
      ...components,
      field: newField
    }
    setComponents(updatedComponents)

    const updatedRuleLogic = createRuleLogic(
      newField,
      updatedComponents.operator,
      parseValueForStorage(updatedComponents.displayValue, updatedComponents.operator)
    )
    onRuleChange(updatedRuleLogic)
  }

  const handleOperatorChange = (newOperator: string) => {
    // When changing operators, we may need to convert the value format
    const parsedValue = parseValueForStorage(components.displayValue, newOperator)

    const updatedComponents = {
      ...components,
      operator: newOperator,
      value: parsedValue
    }
    setComponents(updatedComponents)

    const updatedRuleLogic = createRuleLogic(updatedComponents.field, newOperator, parsedValue)
    onRuleChange(updatedRuleLogic)
  }

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDisplayValue = e.target.value
    const parsedValue = parseValueForStorage(newDisplayValue, components.operator)

    const updatedComponents = {
      ...components,
      displayValue: newDisplayValue,
      value: parsedValue
    }
    setComponents(updatedComponents)

    const updatedRuleLogic = createRuleLogic(updatedComponents.field, updatedComponents.operator, parsedValue)
    onRuleChange(updatedRuleLogic)
  }

  // Helper function to get the field name from its value
  const getFieldName = (fieldValue: string): string => {
    const field = supportedRuleConditionFields.find((f) => f.value === fieldValue)
    return field ? field.name : fieldValue
  }

  // Helper function to format the rule as a sentence
  const formatRuleAsSentence = (): string => {
    const fieldName = getFieldName(components.field)

    // Format based on operator
    switch (components.operator) {
      case '==':
      case 'in':
        return `${fieldName} is ${components.displayValue}`
      case '!=':
      case 'none':
        return `${fieldName} is not ${components.displayValue}`
      default:
        return `${fieldName} ${components.operator} ${components.displayValue}`
    }
  }

  return (
    <div className="ruleLogicFieldsWrapper">
      {editMode ? (
        <>
          <Select
            placeholder="Select field"
            value={components.field}
            onChange={handleFieldChange}
            className="ruleField"
            disabled={!editMode}
          >
            {supportedRuleConditionFields.map((fieldOption) => (
              <Select.Option key={fieldOption.value} value={fieldOption.value}>
                {fieldOption.name}
              </Select.Option>
            ))}
          </Select>

          <Select
            value={components.operator}
            onChange={handleOperatorChange}
            className="ruleField"
            disabled={!editMode}
          >
            {supportedOperators.map((op) => (
              <Select.Option key={op.value} value={op.value}>
                {op.label}
              </Select.Option>
            ))}
          </Select>

          <Input
            placeholder="Enter value"
            value={components.displayValue}
            onChange={handleValueChange}
            className="ruleField"
            disabled={!editMode}
          />
        </>
      ) : (
        // View mode - show Rule as sentence
        <div className="ruleLogicText">{formatRuleAsSentence()}</div>
      )}
    </div>
  )
}
