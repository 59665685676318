import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import { Button, Card, Divider, Row, Space } from 'antd'
import { EligibilityToolSettingsParams, Rule, RuleSet, RuleConditionFields } from './types'
import '../../Shared/Ant/App.less'
import axios from 'axios'
import { PlusOutlined } from '@ant-design/icons'
import { RuleEditor } from './RuleEditor'
import './styles.modules.scss'

const supportedRuleConditionFields: RuleConditionFields[] = [
  { name: 'Property State', value: 'property_state' },
  { name: 'Property Type', value: 'property_type' },
  { name: 'Loan Type', value: 'loan_type' },
  { name: 'Lien Priority Type', value: 'lien_priority_type' },
  { name: 'Loan Program Name', value: 'loan_program_name' }
]

const fetchSettings = async (path: string, companyId: number, setENoteRuleSet: (data: RuleSet) => void) => {
  try {
    const response = await axios.get(path)
    setENoteRuleSet(response.data.enote_eligibility_rule_set)
  } catch (error) {
    console.error('Failed to fetch eligibility settings for company' + companyId, error)
  }
}

const saveRule = async (
  ruleToSave: Rule,
  eligibilityToolSettingsPath: string,
  eNoteRuleSet: RuleSet,
  csrfToken: string
) => {
  try {
    const rulePayload = {
      ...ruleToSave,
      logic: JSON.stringify(ruleToSave.logic)
    }

    const headers = {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      'X-CSRF-Token': csrfToken
    }

    if (ruleToSave.ruleId === 0) {
      const createUrl = `${eligibilityToolSettingsPath}/${eNoteRuleSet.ruleSetId}/rules`
      await axios.post(
        createUrl,
        {
          data: {
            type: 'rules',
            attributes: {
              ...rulePayload
            }
          }
        },
        { headers }
      )
    } else {
      const updateUrl = `${eligibilityToolSettingsPath}/${eNoteRuleSet.ruleSetId}/rules/${ruleToSave.ruleId}`
      await axios.put(
        updateUrl,
        {
          data: {
            type: 'rules',
            id: ruleToSave.ruleId,
            attributes: {
              ...rulePayload
            }
          }
        },
        { headers }
      )
    }
  } catch (error) {
    console.error('Failed to save rule', error)
  }
}

const deleteRule = async (
  ruleId: number,
  eligibilityToolSettingsPath: string,
  eNoteRuleSet: RuleSet,
  csrfToken: string
) => {
  try {
    const deleteUrl = `${eligibilityToolSettingsPath}/${eNoteRuleSet.ruleSetId}/rules/${ruleId}`
    await axios.delete(deleteUrl, {
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        'X-CSRF-Token': csrfToken
      }
    })
  } catch (error) {
    console.error('Failed to delete rule', error)
  }
}

export const EligibilityToolSettings: FC<EligibilityToolSettingsParams> = ({
  companyId,
  eligibilityToolSettingsPath,
  csrfToken
}): ReactElement => {
  const [eNoteRuleSet, setENoteRuleSet] = useState<RuleSet>({ ruleSetId: 0, name: '', rules: [] })
  const [addingRule, setAddingRule] = useState(false)
  const [ruleToSave, setRuleToSave] = useState<Rule | null>(null)
  const [ruleToDelete, setRuleToDelete] = useState<number | null>(null)

  useEffect(() => {
    fetchSettings(eligibilityToolSettingsPath, companyId, setENoteRuleSet)
  }, [eligibilityToolSettingsPath, companyId])

  useEffect(() => {
    if (ruleToSave) {
      saveRule(ruleToSave, eligibilityToolSettingsPath, eNoteRuleSet, csrfToken)
    }
  }, [ruleToSave, eligibilityToolSettingsPath, eNoteRuleSet, csrfToken])

  useEffect(() => {
    if (ruleToDelete !== null) {
      deleteRule(ruleToDelete, eligibilityToolSettingsPath, eNoteRuleSet, csrfToken)
    }
  }, [ruleToDelete, eligibilityToolSettingsPath, eNoteRuleSet, csrfToken])

  const handleRuleChange = (rule: Rule) => {
    setENoteRuleSet({
      ...eNoteRuleSet,
      rules: eNoteRuleSet.rules.map((existingRule) => (existingRule.ruleId === rule.ruleId ? rule : existingRule))
    })
    setRuleToSave(rule)
  }

  const addNewRule = () => {
    setAddingRule(true)
  }

  const saveNewRule = (rule: Rule) => {
    setENoteRuleSet({
      ...eNoteRuleSet,
      rules: [...eNoteRuleSet.rules, rule]
    })
    setAddingRule(false)
    setRuleToSave(rule)
  }

  const onDeleteRule = (ruleId: number) => {
    setENoteRuleSet({
      ...eNoteRuleSet,
      rules: eNoteRuleSet.rules.filter((rule) => rule.ruleId !== ruleId)
    })
    setRuleToDelete(ruleId)
  }

  return (
    <Provider store={store}>
      <div className="eligibilityToolSettings">
        <h2>Eligibility Tool</h2>
        <Card>
          <h3>eNote Eligibility Rules</h3>
          {eNoteRuleSet.rules &&
            eNoteRuleSet.rules.map((rule, index) => (
              <React.Fragment key={index}>
                <Row gutter={[16, 16]}>
                  <Space className="ruleSpace">
                    <RuleEditor
                      rule={rule}
                      onChange={handleRuleChange}
                      supportedRuleConditionFields={supportedRuleConditionFields}
                      onDeleteRule={onDeleteRule}
                      isEditMode={false}
                    />
                  </Space>
                </Row>
                <Divider />
              </React.Fragment>
            ))}
          {addingRule && (
            <React.Fragment>
              <Row gutter={[16, 16]}>
                <Space className="ruleSpace">
                  <RuleEditor
                    rule={{
                      ruleId: 0,
                      name: '',
                      logic: { '==': [{ var: '' }, ''] },
                      ineligibilityReason: '',
                      isReasonCustomized: false
                    }}
                    onChange={saveNewRule}
                    supportedRuleConditionFields={supportedRuleConditionFields}
                    onDeleteRule={() => {}}
                    isEditMode={true}
                  />
                </Space>
              </Row>
              <Divider />
            </React.Fragment>
          )}
          <Row>
            <Button
              type="default"
              icon={<PlusOutlined />}
              onClick={addNewRule}
              style={{ fontSize: '16px', radius: '6px' }}
            >
              Add Rule
            </Button>
          </Row>
        </Card>
      </div>
    </Provider>
  )
}
