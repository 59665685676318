import React, { FC, ReactElement, useState, useEffect } from 'react'
import { Input, Button } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Rule, RuleConditionFields, JsonLogicRule } from './types'
import { RuleLogicEditor } from './RuleLogicEditor'
import { IneligibilityReason } from './IneligibilityReason'
import './styles.modules.scss'

interface RuleEditorProps {
  rule?: Rule
  onChange: (rule: Rule) => void
  onDeleteRule: (ruleId: number) => void
  supportedRuleConditionFields: RuleConditionFields[]
  isEditMode: boolean
}

export const RuleEditor: FC<RuleEditorProps> = ({
  rule,
  onChange,
  onDeleteRule,
  supportedRuleConditionFields,
  isEditMode
}): ReactElement => {
  const [editMode, setEditMode] = useState(isEditMode)
  const [ruleName, setRuleName] = useState<string>(rule?.name || '')
  const [ruleLogic, setRuleLogic] = useState<JsonLogicRule>(() => {
    // Initialize with rule.logic if available, otherwise use default
    if (rule && rule.logic) {
      // Handle case where rule.logic might be a string
      if (typeof rule.logic === 'string') {
        try {
          return JSON.parse(rule.logic)
        } catch (error) {
          console.error('Error parsing rule.logic string:', error)
          return { '==': [{ var: '' }, ''] }
        }
      }

      return rule.logic
    }
    return { '==': [{ var: '' }, ''] }
  })
  const [ineligibilityReason, setIneligibilityReason] = useState<string>(rule?.ineligibilityReason || '')
  const [isReasonCustomized, setIsReasonCustomized] = useState(rule?.isReasonCustomized || false)
  const [customIneligibilityReason, setCustomIneligibilityReason] = useState<string>(
    isReasonCustomized ? ineligibilityReason : ''
  )

  useEffect(() => {
    if (rule) {
      setRuleName(rule.name)

      // Handle case where rule.logic might be a string
      let parsedLogic = rule.logic
      if (typeof rule.logic === 'string') {
        try {
          parsedLogic = JSON.parse(rule.logic)
        } catch (error) {
          console.error('Error parsing rule.logic string in useEffect:', error)
          parsedLogic = { '==': [{ var: '' }, ''] }
        }
      }

      // Only update ruleLogic if it's different from current state
      if (JSON.stringify(parsedLogic) !== JSON.stringify(ruleLogic)) {
        setRuleLogic(parsedLogic)
      }

      setIneligibilityReason(rule.ineligibilityReason || '')
      setIsReasonCustomized(rule.isReasonCustomized || false)
    }
  }, [rule])

  const onChangeRuleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRuleName(e.target.value)
  }

  const handleRuleLogicChange = (updatedRuleLogic: JsonLogicRule) => {
    setRuleLogic(updatedRuleLogic)
  }

  const onSaveRule = () => {
    if (customIneligibilityReason !== '') {
      setIneligibilityReason(customIneligibilityReason)
      setIsReasonCustomized(true)
    } else {
      setIsReasonCustomized(false)
    }

    if (rule) {
      // Update existing rule
      onChange({
        ...rule,
        name: ruleName,
        logic: ruleLogic,
        ineligibilityReason,
        isReasonCustomized
      })
    } else {
      // Create new rule with a temporary ID (backend will assign real ID)
      onChange({
        ruleId: Date.now(), // Temporary ID
        name: ruleName,
        logic: ruleLogic,
        ineligibilityReason,
        isReasonCustomized
      })
    }
    setEditMode(false)
  }

  const onClickEdit = () => {
    setEditMode(true)
  }

  const onClickCancelEdit = () => {
    setEditMode(false)
  }

  return (
    <>
      <div className="ruleEditorHeaderWrapper">
        <div>
          {!editMode && <div className="ruleNameText">{ruleName}</div>}
          {editMode && (
            <Input placeholder="Rule name" value={ruleName} onChange={onChangeRuleName} className="ruleNameEditor" />
          )}
        </div>
        <div className="ruleEditorActionButtons">
          {!editMode && (
            <Button
              type="default"
              icon={<DeleteOutlined />}
              onClick={() => onDeleteRule(rule?.ruleId || 0)}
              className="deleteButton"
            />
          )}
          {!editMode && <Button type="default" icon={<EditOutlined />} onClick={onClickEdit} className="editButton" />}
          {editMode && (
            <Button type="primary" onClick={onSaveRule} className="saveButton">
              Save
            </Button>
          )}
          {editMode && (
            <Button type="text" onClick={onClickCancelEdit} className="saveButton">
              Cancel
            </Button>
          )}
        </div>
      </div>

      <RuleLogicEditor
        ruleLogic={ruleLogic}
        supportedRuleConditionFields={supportedRuleConditionFields}
        editMode={editMode}
        onRuleChange={handleRuleLogicChange}
      />

      <IneligibilityReason
        ineligibilityReason={ineligibilityReason}
        customIneligibilityReason={customIneligibilityReason}
        isCustomized={isReasonCustomized || false}
        setCustomIneligibilityReason={setCustomIneligibilityReason}
        setEditMode={setEditMode}
        editMode={editMode}
      />
    </>
  )
}
